

.footer {
    /* position: absolute; */
    text-align: center;
    background-color: #8C1C56;
    color: white;
    
  }

  .logoLabelWrapper{
    margin-top: 10px;
    margin-bottom: -10px;
  }
  .bwLogoLabel{
    color: #ff9fa7;
  }
.bwLogoContainer{
  width: 100%;
  box-shadow: 1px 2px 0 rgba(255,159,16, 0.1);
}

.copyrightText{
  margin: 5px;
}
