@font-face {
  font-family: 'GlacialIndifference';
  src: url('../fonts/GlacialIndifference-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GlacialIndifference';
  src: url('../fonts/GlacialIndifference-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

.landing-wrapper{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.flex-filler{
  flex-grow:1;
  height: 100px;
}
.landingHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.landingLogo{
    margin: 1% 0 0 5%;
}

.landingLogin{
    font-family: 'GlacialIndifference', sans-serif;
    font-weight: bold;
    font-size: 1.5em;
    color: #999999;
    margin: 3% 5% 0 0;
    cursor: pointer;
}

.main{
  
    font-family: 'GlacialIndifference', sans-serif;
    margin: 100px 11% 0 11%;
    
}
.landing-main-text{
    font-weight: bold;
    font-size: 4em;
    text-align: left;
    height: 100%;
    min-height: 40vh;
    
}
.crbonScope-purple-bold{
color: #8C1C56;
}
.text-grey-bold{
color: #999999;

}
.typewriter-text {
  opacity: 0;
  transition: opacity 0.5s ease-in;
}

.introducing-text-grey{
    font-size: 1.5em;
    text-align: left;
    color: #999999;
    width: 50vw;
    
}

.button-container{
    text-align: right;
}


/* .btn-gradient-filled {
  font-family: 'GlacialIndifference', sans-serif;
  font-size: 1.5em;
  font-weight: bold;
  color: white;
  background: linear-gradient(to bottom right, #FF9EA6 0%, #FF7A7F 20%, #8B1C56 55%, #600934 0%,#8B1C56 0%,#FF7A7F 90%);
  width: 10vw;
  height: 8vh;
  border-radius: 20px;
  border: none;
  transition: background-position 0.5s ease-in-out;
  background-size: 200% 200%; 
}

.btn-gradient-filled:hover {
  background-position: right center; 
} */
.btn-gradient-filled {
  font-family: 'GlacialIndifference', sans-serif;
  font-size: 1.2em;
  font-weight: bold;
  color: white;
  background: linear-gradient(to right, #FF9EA6 0%, #FF7A7F 20%, #8B1C56 100%, #600934 100%);
  width: 150px;
  height: 60px;
  border-radius: 20px;
  border: none;
  transition: background-position 0.7s ease-in-out;
  background-size: 200% 200%; 
  margin-bottom: 50px;
  margin-top: 20px;
  cursor: pointer;
}

.btn-gradient-filled:hover {
  background-position: right ; 
}

.btn-gradient-filled:active {
  animation: buttonActiveAnimation 0.1s ease-in-out;
}

@keyframes buttonActiveAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}
.link-pointer{
  cursor: pointer;
}
footer {
  margin-top: auto;
}
.landing-footer {
  font-family: 'GlacialIndifference', sans-serif;
  display: flex;
  margin-left: 5%;
  margin-bottom: 1%;
}
.footer-space{
    margin: 0 2vw 0 2vw
}

@media (max-width: 1920px){
    .landing-main-text{
  font-size: 4.7em;
        min-height: 30vh;
    }
}
@media (max-width: 1500px){
    .landing-main-text{
        font-size: 4em;
        min-height: 35vh;
    }
}

@media (max-width: 1250px){
    .landing-main-text{
        font-size: 3em;
        min-height: 30vh;
    }
}
@media (max-width: 800px){
  .landing-main-text{

        
    }
    .landingLogin{
      margin-top: 35px;
    }
   .button-container{
    text-align: left;
}
}

@media (max-width: 560px){
   .landing-main-text{
    font-size: 2.5em;
    min-height: 40vh;
    }
    .introducing-text-grey{
    font-size: 1.3em; 
} 
.landing-footer{
  flex-direction: column;
  
}
}

@media (max-width: 400px){
   .landing-main-text{
    font-size: 2.5em;
    min-height: 45vh;
    }
    .introducing-text-grey{
    font-size: 1.3em; 
} 
}
    
