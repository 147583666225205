

.logoContainer{
    display: flex;
    justify-content: center;
    margin: 2%;
}
.loginContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
}
.loginCard{
    width: 50vw;
    height: 35vh;
    border-radius: 20px;
    
}
@media (max-width: 750px){
    .loginCard{
        width: 80vw;
    }
}
.loginTitle{
    font-family: 'GlacialIndifference', sans-serif;
    font-size: 2.5em;
}

.flex-column{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.buttonContainer{
    margin: 10%;
}
.loginButton {
  font-family: 'GlacialIndifference', sans-serif;
  font-size: 1.2em;
  font-weight: bold;
  color: white;
  background: linear-gradient(to right, #FF9EA6 0%, #FF7A7F 20%, #8B1C56 100%, #600934 100%);
  width: 225px;
  height: 50px;
  border-radius: 15px;
  border: none;
  transition: background-position 0.7s ease-in-out;
  background-size: 200% 200%; 
  margin-bottom: 50px;
 
  cursor: pointer;
}

.loginButton:hover {
  background-position: right ; 
}

.loginButton:active {
  animation: buttonActiveAnimation 0.1s ease-in-out;
}

@keyframes buttonActiveAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

.error-text{
  color: red;
  margin-top: 10px;
  text-align: left;
  width: 220px;
}

@media (max-width: 500px){
    .loginButton{
        width: 150px;
        font-size: 1em;
    }
    .passwordInput{
        width: 150px;
    }
    .error-text{
      width: 145px;
    }
}