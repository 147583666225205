.allInputs{
    margin: 30px 0 50px 0;
}

.flexWrapper{
    display: flex;
   
}
.flex-center-row{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.coordinateContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.openformLabels{
    color: black !important;
    font-size: 20px !important;
    display: inline-block;
    float: left;
    clear: left;
    width: 250px;
    text-align: right;
    margin: 4px 10px 10px -130px;
}

.openformInputs{
    float: left;
    margin-bottom: 2% !important;
    width: 50%;
    
}
/* Gets rid of arrow buttons on number inputs*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.numberFormLabels{
    
    color: black !important;
    font-size: 17px !important;
    text-align: left;
    margin: 8px 0 5px 0;
    word-wrap: break-word;
    font-weight: 400;
    
}

.numberFormInputs{
    flex-basis: 60%;
}

.selectFormInputs{
    margin-left:4%;
    flex-basis: 20%;
}
.selectBoxLabels{
    float: left;
}

.uwiLabels{
    width: 100%
}
.uwiTextField{
    margin-top: 10px;
}



.selectedToggleOption{
    background-color: #ff9fa7 ;
    color: white;
}
.unselectedToggleOption{
    background-color: white;
    color:#ff9fa7;
}

.requirementAlertWrapper{
   display: flex;
   justify-content: center;
   text-align: left;
   
}

.requiremetAlertComponent{
    width: 450px;
    margin: 0.5%;
}

.mapContainer {
    width: 100%;
    height: 30vh;
    margin-top: 20px;
}

