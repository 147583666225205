

.container {
  display: flex;
  flex-direction: column;
min-height: 100vh;
}

.content {
  flex-grow: 1;
}

.mainAccordian{
    margin: auto;
    margin-bottom: 10px;
    width: 75%;
    
    
}

.accordianWrapper{
    justify-content: left;
    
}

.accordianSummary{
    box-shadow: 1px 2px 0 rgba(0, 0, 0, 0.1);
    

}
.tabSummary{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.myAccordionSummaryClass.MuiAccordionSummary-root {
  background-color: white !important;
}

.myAccordionSummaryClass.MuiAccordionSummary-root:hover:not(.Mui-focusVisible) {
  background-color: white !important;
}

.mainButtonContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    
}
.calculateButton{
    margin: 1% !important;
    color: black !important;
    background-color: #ff9fa7 !important;
    font-weight: 700 !important;
    text-transform: capitalize !important;
    font-size: 1em !important;
    width: 7em;
}

.mapContainer {
    width: 100%;
    height: 40vh;
}


.reportButton{
    margin: 50px !important;
    color: white ;
    background-color: #8C1C56 !important;
    font-weight: 700 !important;
    text-transform: capitalize !important;
    font-size: 18px !important;
}

.resultLabel{
    color: black;
}

.resultBox{
    font-size: 200px;
}

.requirementWrapper{
    display: flex;
    justify-content: center;
}

.flexResultContainer{
    display: flex;
    flex-direction: column;
    justify-self: center;
}
